<script setup lang="ts">
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import Vue3RuntimeTemplate from 'vue3-runtime-template'
import type { SbSectionStoryblok } from '~/types/storyblok'

const props = defineProps<{ blok: SbSectionStoryblok }>()

const mobileBackgroundImage = computed(() => generateBackground(props.blok.mobileBackgoundImage?.filename))
const tabletBackgroundImage = computed(() => generateBackground(props.blok.tabletBackgoundImage?.filename))
const smallDesktopBackgroundImage = computed(() => generateBackground(props.blok.smallDesktopBackgoundImage?.filename))
const largeDesktopBackgroundImage = computed(() => generateBackground(props.blok.largeDesktopBackgoundImage?.filename))

// TODO: Find solution to use tailwind config instead
const colorsMap = {
  primary: '#F5EFFF',
  secondary: '#D6E0F2',
  passive: '#F5F5F5',
} satisfies Record<keyof SbSectionStoryblok['backgroundColor'], 'string'>

const section = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)

const isHighlighted = ref(
  Boolean(
    props.blok.backgroundColor
    || props.blok.highlighted
    || props.blok.mobileBackgoundImage?.filename
    || props.blok.tabletBackgoundImage?.filename
    || props.blok.smallDesktopBackgoundImage?.filename
    || props.blok.largeDesktopBackgoundImage?.filename,
  ),
)

const renderedHeading = computed(() => props.blok.title?.content ? renderRichText(props.blok.title) : '')

function generateBackground(image?: string) {
  const bgColor = props.blok.backgroundColor ? colorsMap[props.blok.backgroundColor] : ''

  if (!image) {
    return bgColor || 'none'
  }

  return `${bgColor} url(${image}) no-repeat center top / contain`
}
</script>

<template>
  <section
    v-editable="blok"
    class="sb-section flex flex-col justify-center"
    :class="{
      'sb-section__highlighted': isHighlighted || blok.highlighted,
    }"
  >
    <div
      ref="section"
      :class="{
        'bg-brand-light': blok.highlighted,
        'pb-0': !blok.highlighted && !isHighlighted,
        'sb-section-hightlight-target': !blok.backgroundTarget || blok.backgroundTarget === 'section',
      }"
    >
      <div
        :class="{
          'container': blok.backgroundTarget !== 'container',
          'px-1 lg:container': blok.backgroundTarget === 'container',
        }"
      >
        <div
          ref="container"
          :class="{
            'sb-section-hightlight-target rounded-xl px-5 lg:px-[8%]': (!!isHighlighted || blok.highlighted) && blok.backgroundTarget === 'container',
          }"
        >
          <div class="mx-auto flex w-full max-w-[53rem] flex-col md:text-center">
            <CfgTypography
              v-if="blok.tag"
              class="mb-2 text-grey-300"
              :size="CfgTypographySizes.h4"
              tag-name="p"
            >
              {{ blok.tag }}
            </CfgTypography>

            <BaseSanitizedHTML :allowed-tags="['span', 'h1', 'h2', 'h3', 'h4', 'h5']" :html="renderedHeading">
              <template #default="{ html }">
                <TypographyStyledText>
                  <Vue3RuntimeTemplate :template="html" />
                </TypographyStyledText>
              </template>
            </BaseSanitizedHTML>

            <CfgTypography
              v-if="blok.description"
              class="mx-auto mt-5 max-w-[41rem] !font-normal"
              :size="CfgTypographySizes.subtitle"
              tag-name="p"
            >
              {{ blok.description }}
            </CfgTypography>
          </div>

          <div
            v-if="blok.body?.length"
            class="grid grid-cols-12"
            :class="{
              'mt-9 md:mt-12': renderedHeading || blok.description,
            }"
          >
            <div
              class="col-span-12"
              :class="{
                'xl:col-span-8 xl:col-start-3': blok.contentWidth === 's',
                'xl:col-span-10 xl:col-start-2': blok.contentWidth === 'm',
              }"
            >
              <StoryblokComponent
                v-for="bodyBlok in blok.body"
                :key="bodyBlok._uid"
                :blok="bodyBlok"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.sb-section-hightlight-target {
  background: v-bind(mobileBackgroundImage);

  @screen md {
    background: v-bind(tabletBackgroundImage);
  }

  @screen lg {
    background: v-bind(smallDesktopBackgroundImage);
  }

  @screen 2xl {
    background: v-bind(largeDesktopBackgroundImage);
  }
}
</style>
